<template>
  <div>
    <Navbar page="Diagnósticos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8 ">

      <div class="flex gap-2">
        <router-link :to="`${route}/form`" type="button" 
          class="inline-block transition duration-200 bg-green-600 hover:bg-green-700 text-white py-2 
          rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
          mb-4">
            <span class="inline-block mr-2">Novo diagnóstico</span>
        </router-link>

        <router-link :to="`${route}/gerenciar`" type="button" 
          class="inline-block transition duration-200 bg-red-600 hover:bg-red-700 text-white py-2 
          rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
          mb-4
          "
        >
            <span class="inline-block mr-2">Gerenciar templates de diagnósticos</span>
        </router-link>

        <div class="col-span-12 2xl:col-span-4 flex py-2">
            <input 
              type="checkbox" 
              v-model="arquivados" 
              @change="updateFiltro" 
              class="rounded-sm" 
              id="visualizarArquivados"
            />
            <label for="visualizarArquivados" class="block text-sm font-medium ml-2">
                Visualizar arquivados
            </label>
        </div>
        
      </div>

      <button 
        @click="excluirSelecionadas" 
        v-if="!$store.state.user.cliente && selecionados.length && !arquivados" 
        type="button" 
        class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center ">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Tipo de diagnóstico
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Tema
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Vínculo ao PAI
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Destinatário / Setor
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Nível conformidade
                      </th>
                      <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Avanço no PAI
                      </th>
                      <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200" >
                    <tr v-for="item in list" :key="item._id"  
                    :class="{
                      'bg-red-400 text-white': !item.ativo, 
                      'text-gray-500': item.ativo
                      }" 
                    >
                      <td class="px-3 py-4">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm">
                            {{ item.tipoDiagnostico}}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm">
                            {{ item.diagnosticoBase ? item.diagnosticoBase.tema : '' }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div :class="' text-sm ' + (item.vinculado_pai ? ' text-blue-500':' text-red-400') ">
                            {{ item.vinculado_pai ? 'Sim' : 'Não' }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-col items-center">
                          
                            <span v-if="item.quantidadeDestinatarios > 1" >
                              Vários ({{ item.quantidadeDestinatarios }})
                            </span>
                            
                            <span v-if="item.destinatariosPessoas.length === 1 && item.quantidadeDestinatarios === 1">
                              {{ `${item.destinatariosPessoas[0].destinatario.nome} ${item.destinatariosPessoas[0].destinatario.setor ? `- ${item.destinatariosPessoas[0].destinatario.setor.nome}` : ''}` }}
                            </span>

                            <span v-if="item.destinatariosAgente.length === 1 && item.quantidadeDestinatarios === 1">
                              {{ `${item.destinatariosAgente[0].destinatario.nome}` }}
                            </span>
                          
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-col items-center">
                          <span v-if="item.destinatariosPessoas.length">
                            <span v-show="item.destinatariosPessoas.length > 1" >
                              Vários
                            </span>
                            <span v-show="item.destinatariosPessoas.length === 1">
                              {{ `${item.destinatariosPessoas[0].status}` }}
                            </span>
                          </span>

                          <span v-else>
                            <span v-show="item.destinatariosAgente.length">
                              <span v-show="item.destinatariosAgente.length > 1">
                                Vários
                              </span>
                              <span v-show="item.destinatariosAgente.length === 1">
                                {{ `${item.destinatariosAgente[0].status}` }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="text-center">
                           <span>-</span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="text-center">
                          <span>-</span>
                        </div>
                      </td>
                      <td class="flex flex-wrap px-6 py-4 text-sm font-medium gap-0.5 items-center">
                        <button v-if="!item.enviada && item.ativo && !$store.state.user.cliente" @click="modalEnviar(item)" class="text-white hover:bg-green-500 bg-green-700 rounded py-1 px-4 mx-1">
                          Enviar
                        </button>
                          <router-link 
                            type="button" 
                            v-if="!$store.state.user.cliente && item.ativo" 
                            :to="`${route}/form/${item._id}`" 
                            class="text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mx-1 ">
                              Editar
                          </router-link>
                          <router-link 
                            type="button" 
                            v-if="!$store.state.user.cliente && item.ativo" 
                            :to="`${route}/visualizar/${item._id}`" 
                            class="text-white hover:bg-gray-500 bg-gray-400 rounded py-1 px-4 mx-1 ">
                              Visualizar
                          </router-link>
                          <button
                            v-if="!$store.state.user.cliente && item.ativo"
                            @click="duplicar(item._id)" 
                            href="#" 
                            class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1 ">
                            Duplicar
                          </button>
                          <button
                            v-if="!$store.state.user.cliente && !arquivados"
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Arquivar
                          </button>
                          <button
                            v-else-if="!$store.state.user.cliente && arquivados"
                            @click="ativar(item._id)" 
                            type="button" 
                            class="text-white bg-green-600 hover:bg-green-700 rounded py-1 px-4 mx-1 ">
                            Ativar
                          </button>
                          <button
                            v-if="arquivados"
                            @click="removeArquivada(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 ">
                            Excluir
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
export default {
  data() {
    return {
      route: '/diagnosticos',
      form: {
        responsavel: null,
        fornecedor: null,
        tratamento: '',
        tipo_responsavel:'',
      },
      arquivados: false,
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {
    formatDate(data) {
      return `${moment(data).format("DD/MM/YYYY")}`;
    },
    async start() {
      console.log('start')
      await this.$http.post(`/v1/diagnosticoControle/list`, { 
        arquivados: this.arquivados, 
        skip: this.query.skip, 
        limit: this.query.limit  
      })
      .then((resp)=>{
        this.list = resp.data.data;
        this.total = resp.data.total;
      })
      
    },
    async modalEnviar(diagnostico){
      this.$confirm({
        title: 'Enviar diagnóstico',
        message: `Deseja enviar o diagnóstico para o(s) destinatários ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/diagnosticoAplicado/`, {_id: diagnostico._id})
          .then((resp)=>  this.$vToastify.success(resp.data.msg))
          this.start();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/diagnosticoControle/selecionados/deletar`,{ selecionados: this.selecionados })
          .then((resp)=>{
            this.$vToastify.success(resp.data.msg);
            this.selecionados = [];
            this.start();
          })
          .catch(()=> this.$vToastify.error('Erro ao arquivar itens.'))
        }
      })
    },

    async removeArquivada (id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir esse diagnóstico arquivado?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticoControle/remover/${id}`);
              this.$vToastify.success("Excluído com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticoControle/${id}`)
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao arquivar item.'))
            }
          }
      });
    },

    async ativar(id) {
    this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.put(`/v1/diagnosticoControle/`, { _id: id, ativo: true })
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao ativar item.'))
            }
          }
      });
    },

    async duplicar(id) {
      await this.$http.post(`/v1/diagnosticoControle/duplicar/`,{ _id: id })
      .then(()=>{
        this.$vToastify.success("Duplicado com sucesso!");
        this.start();
      })
      .catch(()=>this.$vToastify.error('Erro ao duplicar item.'))
      
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        arquivados: this.arquivados 
      };
      this.$router.push({ path: `${this.route}`, query: this.query });
    },
    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/diagnosticoControle/list`, { all: true, arquivados: this.arquivados });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;

      this.$router.push({ path: `${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>